import { useState } from "react";
import { useFetchAllData } from "./useFetchData";
import { checkFile } from "../Components/Functions/checkFile";
import Api from "../Api/api";
interface Promo {
    id?: number,
    name?: string,
    image_url?: string
}
export const usePromo = () => {
    const [openModal, setOpenModal] = useState(false);
    const [item, setItem] = useState<Promo>()
    const [file, setFile] = useState<any>(null);
    const [error, setError] = useState<any>(null);
    const url = "https://lagranderesidence.com/uploads/"
    const { data, setUpdate, update } = useFetchAllData("promo")
    const sortedData = [...data].sort((a, b) => b.id - a.id);

    const editItem = (item: any) => {
        setItem(item)
        setFile(null)
        setOpenModal(true)

    }
    const handleChange = (event: any) => {
        const { file, error } = checkFile(event)
        setFile(file)
        setError(error)
    };
    const handleSave = () => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
                Api.upload("image", formData).then((data: any) => {
                    const { file_url } = data
                    const request: any = {
                        name: item?.name,
                        image_url: file_url
                    }
                    if (item?.image_url) {
                        const id = item.image_url.replace(url, "")
                        request["id"] = item?.id
                        Api.update("promo", request).then(() => {
                            setOpenModal(false)
                            setUpdate(!update)
                        })

                    }
                    else {
                        Api.post("promo", request).then((data) => {
                            setOpenModal(false)
                            setUpdate(!update)
                        }

                        )
                    }

                })

            return;
        }
        if (item?.image_url) {
            Api.update("promo", item).then(() => {
                setUpdate(!update)
                setOpenModal(false)
            })
        }


    }
    const handleDelete = () => {
            Api.delete("promo", item?.id).then(() => {
                setOpenModal(false)
                setUpdate(!update)
            })
    }
    return { sortedData, openModal, setOpenModal, editItem, handleChange, handleDelete, handleSave, setFile, file, error, setError, item, setItem }
}