
import { useState, useEffect } from "react";
import Api from "../Api/api";
import { init } from "../Utility/Objects/Gallery";
import { checkFile } from "../Components/Functions/checkFile";


export const useGallery = () =>{
    const [category, setCategory] = useState<any>()
    const [update, setUpdate] = useState<any>(true)
    const [modal, setModal] = useState(false)
    const [file, setFile] = useState<any>([]);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState(false)
    const [newData, setNewData] = useState<any>(init)
    const [data, setData] = useState<any>([])
    const [totalPage, setTotalPage] = useState(1)
    const [page, setPage] = useState(1)
    const [dataId, setDataId] = useState<any>()
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        setCategory(searchParams.get('category'))
        const fetchData =   async () => {
            setTotalPage(1)
            setPage(1)
            setData([])
            await Api.getall(`gallery/${searchParams.get('category')}`).then((data: any) => {
                const { total_pages } = data
                setData(data.data)
                setTotalPage(total_pages)

            })
        }
        fetchData();
        Api.getall(`gallery/${searchParams.get('category')}?order=asc`).then((data: any) => {

            const { total_pages } = data
            if (total_pages > 30) {
                deleteData(data.data)
            }
        })


    }, [update])
    const appendData = () => {

        Api.getall(`gallery/${category}?page=${page + 1}`).then((data: any) => {
            setData((prevDataList: any) => [...prevDataList, ...data.data]);
            setPage(page + 1)

        })
    }
    const openModal = (data: any) => {
        setModal(true)
        if (data.id) {
            setDataId(data.id)
            setNewData(data.image_list)
        }
        else { setNewData(init) }
    }
    const handleChange = (event: any, key: number) => {
        const name = event.target.name;
        const {file, error} = checkFile(event)
        if(!file){
          setFile(file)
          setError(error)
          return
        }
        setFile({ ...file, [name]: file });
        updateNewData(key, file);
        setError(null);
        return
    };

    const updateNewData = (key: number, selectedFile: File) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            setNewData((prevData: any[]) => ({
                ...prevData,
                [key]: {
                    ...prevData[key],
                    image_url: e.target?.result
                }
            }));
        };
        reader.readAsDataURL(selectedFile);
    };
    const handleSave = async () => {
        setLoading(true)
        let image_list: string[] = []
        console.log(Object.keys(file).length)
        
        if (Object.keys(file).length === 1) {

            setError(null)
            for (const [key, value] of Object.entries(file)) {
                if (value instanceof File) {
                    const formData = new FormData();
                    formData.append('file', value);

                    try {
                        // Await the result of the upload operation
                        const data = await Api.upload("image", formData);
                        const { file_url } = data;
                        image_list.push(file_url)
                        console.log(file_url)
                    } catch (error) {
                        // Handle upload error if needed
                        console.error(`Error uploading ${key}:`, error);
                    }
                }
            }

            Api.post("gallery", { category: category, image_list: image_list }).then((data: any) => {
                if (data.message === "success") {
                    setModal(false)
                    setUpdate(!update)
                }
                setLoading(false)

            })
        }
        else {
            setError('Image Incomplete')
        }
        // Now data_ should be populated with file URLs


    }

    const handleUpdate = async () => {
        setLoading(true)
        setError(null)
        for (const [key, value] of Object.entries(file)) {
            if (value instanceof File) {
                const formData = new FormData();
                formData.append('file', value);

                try {
                    // Await the result of the upload operation
                    const data = await Api.upload("image", formData);
                    const { file_url } = data;
                    console.log(key, file_url)
                    await Api.update(`gallery`, { id: key, image_url: file_url })
                } catch (error) {
                    // Handle upload error if needed
                    console.error(`Error uploading ${key}:`, error);
                }
            }
        }
        setModal(false)
        setUpdate(!update)


        // Now data_ should be populated with file URLs


    }
    const deleteData = async (data: any) => {
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(data)) {

            if (typeof value === "string" && value.includes("https")) {
                const id = value.replace("https://lagranderesidence.com/uploads/", "")
                await Api.delete("image", id)
            }

        }
        await Api.delete("gallery", dataId).then(() => {
            setModal(false)
            setUpdate(!update)
        })

    }
    return {data,openModal,setModal,modal,loading,error,totalPage,setNewData,newData,setUpdate,category,handleChange,update,page,appendData,dataId,setFile,init,deleteData,handleUpdate,handleSave,setDataId}
}