import React, { useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { Sphere, Html, OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import ThreeSixtyLGRConferenceRoomPic from "../../assets/360Pics/LGR 360 TOUR PICS/Conference Room.jpg"
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

interface HotspotProps {
    position: [number, number, number];
    onClick: () => void;
    label: string;
}



const Hotspot: React.FC<HotspotProps> = ({ position, onClick, label }) => {
    const [isAnimating, setIsAnimating] = useState(false);

    const handleClick = () => {
        setIsAnimating(true);
        // Trigger the animation and reset after a short delay
        setTimeout(() => {
            onClick(); // Perform the click action (navigate, etc.)
        }, 500); // The animation duration
        setTimeout(() => {
            setIsAnimating(false); // Reset the animation
        }, 700); // Delay to match the animation time
    };

    return (
        <Html position={position}>
            <button
                className={`bg-white text-black p-2 rounded-full shadow-lg hover:bg-gray-200 transition duration-300 ${isAnimating ? 'hotspot-animation' : ''}hotspot-jump`}
                onClick={handleClick}
                style={{
                    width: '150px', // Adjust the width here
                    height: '50px', // Adjust the height here
                    fontSize: '14px', // Adjust font size
                }}
            >
                {label}
            </button>
        </Html>
    );
};

const ThreeSixtyLGRConferenceRoom: React.FC = () => {
    const [currentImage, setCurrentImage] = useState<string>(ThreeSixtyLGRConferenceRoomPic);
    const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
    const navigate = useNavigate(); // Use the useNavigate hook for navigation

    // Load the texture dynamically based on the current image state
    const texture = new THREE.TextureLoader().load(currentImage);

    const handleHotspotClick = (image: string, path: string) => {
        setIsTransitioning(true); // Start the zoom-in transition
        setCurrentImage(image);

        // Start the transition
        setTimeout(() => {
            navigate(path); // Navigate to the new page after the transition duration
        }, 500); // Match this with the transition duration
    };

    return (
        <div className={`w-full h-screen bg-black transition-opacity duration-500 ${isTransitioning ? 'opacity-0' : 'opacity-100'}`}
            style={{ position: 'relative' }}
        >
            <Canvas
                camera={{
                    position: [80, 0, 10], // Adjust this value to position the camera
                    fov: 75, //Field of View (adjust as needed)
                    near: 0.1,
                    far: 10000
                }}
            >
                <Sphere args={[500, 60, 40]} scale={[-1, 1, 1]}>
                    <meshBasicMaterial map={texture} side={THREE.DoubleSide} />
                </Sphere>

                {/* Hotspots with navigation */}
                <Hotspot
                    position={[2000, -800, -200]}
                    label="Go Back ↑"
                    onClick={() => handleHotspotClick('/360-LGR-16th-Floor2', '/360-LGR-16th-Floor2')}
                />

                <OrbitControls enableZoom={false} />
            </Canvas>
        </div>
    );
};

export default ThreeSixtyLGRConferenceRoom;