import { useEffect, useState } from "react";
import DriverLogin from "./DriverLogin";
import Api from "../../../Api/api";
import time_convert from "../../../Components/Functions/time_convert";
import PageBanner from "../../../Components/PageBanner";
export default function DriverPortal() {
    const [formdata, setFormdata] = useState<any>({})
    const page = sessionStorage.getItem("page")
    const driverString = localStorage.getItem("driver");
    let driver: any; // Or specify the type of your driver object
    useEffect(()=>{
        const session = sessionStorage.getItem("driverform")
        if(session){
            setFormdata(JSON.parse(session))
        }
        
    },[])
   
    useEffect(()=>{
        sessionStorage.setItem("driverform",JSON.stringify(formdata))
    },[formdata])
    if (driverString !== null) {
        driver = JSON.parse(driverString);
    } else {
        // Handle the case where the data is not found in localStorage
        console.error("Driver data not found in localStorage");
    }
    const onsave = () => {
        const currentDate = new Date();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
        const day = currentDate.getDate().toString().padStart(2, '0');
        const year = currentDate.getFullYear();
        const time = currentDate.getHours() + ':' + currentDate.getMinutes()
        const formattedDateTime = `${month}/${day}/${year} ${time_convert.time(time)}`;
        let value: any[] = []
        let range = page;
        if (driver.type === "Driver") {
            if (page === "Free Shuttle") {
                value = [formattedDateTime, driver.name, formdata.vehicle, formdata.type_service, formdata.type_passenger, time_convert.time(formdata.departure), formdata.start_millage, formdata.pax, formdata.destination, time_convert.time(formdata.arrival), formdata.end_millage, formdata.remarks]
            }
            if (page === "Paid Shuttle") {
                value = [formattedDateTime, driver.name, formdata.vehicle, formdata.unit_no, formdata.type_shuttle, formdata.type_service, time_convert.time(formdata.departure), formdata.start_millage, formdata.pax, formdata.destination, time_convert.time(formdata.arrival), formdata.end_millage, formdata.price, formdata.remarks]
            }
            if (page === "Zermatt Shuttle") {
                value = [formattedDateTime, driver.name, formdata.vehicle, formdata.name_passenger, formdata.type_service, time_convert.time(formdata.departure), formdata.start_millage, formdata.pax, formdata.destination, time_convert.time(formdata.arrival), formdata.end_millage, formdata.remarks, '', formdata.price]
            }
            if (page === "Expense Tracker") {
                range = "Driver's Expenses Report"
                value = [formattedDateTime, driver.name, formdata.vehicle, formdata.type_e, formdata.price, formdata.start_millage, formdata.end_millage, formdata.remarks]

            }

            Api.post("spreadsheet", {
                "id": "1xHmTDRc-tiWM0Pn6no2Iq7vDTBHhAlmMKKgcLV6tNMc",
                "range": `${range}!A:A`,
                "values": [
                    value
                ]
            }).then((data:any)=>{
                
                if (data.message === "Data added successfully."){
                   
                    sessionStorage.clear()
                }
                alert(data.message)
                window.location.reload()
            })
        }

    }
    if (!driver) {
        return <DriverLogin />
    }
    return (
        <section className={`bg-center bg-fixed text-lagrand-white ${!page && "h-screen"} md:bg-cover landing bg-gray-400 bg-blend-multiply`}>
            <PageBanner Heading="Driver's Portal" SubHeading="Driver's Portal"/>
            {page &&
            <div className="lg:px-[200px] bg-lagrand-white pt-5 md:px-[100px] xl:px-[500px] px-5">


                <form onSubmit={(e) => {
                    e.preventDefault();
                    onsave();
                }} >
                    <h1 className="text-3xl font-thin mb-5 text-black text-center">{page}</h1>
                    <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <div className="col-span-2">
                            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Driver's Name : {driver.name} <p onClick={ 
                                ()=>{localStorage.clear()
                            window.location.reload()}
                            } className="text-red-600 underline">Logout</p></label>
                            </div>
                        <div>
                            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Vehicle</label>
                            <select id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={(e) => {
                                    
                                    const { value } = e.target;
                                    setFormdata({ ...formdata, vehicle: value });
                                  // If value is undefined, set it to an empty string
                                }}
                            >
                                <option selected>{formdata.vehicle ? formdata.vehicle :'Choose a vehicle'}</option>
                                <option value="Innova">Innova</option>
                                <option value="L300">L300</option>
                                <option value="Accent">Accent</option>
                            </select>
                        </div>
                        {page === "Paid Shuttle" && <> <div>
                            <label htmlFor="website" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Unit No.</label>
                            <input value= {formdata.unit_no} onChange={(e) => {
                               
                                const { value } = e.target;
                                setFormdata({ ...formdata, unit_no: value });
                                // If value is undefined, set it to an empty string
                            }}
                                type="text" id="website" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="unit no." />
                        </div>
                            <div>
                                <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Type of Shuttle</label>
                                <select onChange={(e) => {
                                    const { value } = e.target;
                                    setFormdata({ ...formdata, type_shuttle: value }); // If value is undefined, set it to an empty string
                                }}
                                    id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option selected>{formdata.type_shuttle ? formdata.type_shuttle :'Choose a type'}</option>
                                    <option value="Paid">Paid</option>
                                    <option value="Charged">Charged</option>
                                    <option value="Free">Free</option>
                                </select>
                            </div>
                        </>
                        }
                        {page === "Expense Tracker" &&
                            <div>
                                <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Type of Expenses</label>
                                <input value={formdata.expenses} onChange={(e) => {
                                    const { value } = e.target;
                                    setFormdata({ ...formdata, expenses: value }); // If value is undefined, set it to an empty string
                                }}
                                    type="text" id="visitors" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="expenses" />

                            </div>}
                        {page !== "Expense Tracker" &&
                            <div>
                                <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Type of Service</label>
                                <select onChange={(e) => {
                                    const { value } = e.target;
                                    setFormdata({ ...formdata, type_service: value }); // If value is undefined, set it to an empty string
                                }} id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option selected>{formdata.type_service ? formdata.type_service :'Choose a sevice'}</option>
                                    <option value="Pick Up">Pick Up</option>
                                    <option value="Drop Off">Drop Off</option>
                                    <option value="Pick Up & Drop">Pick Up & Drop</option>
                                    {page === "Free Shuttle" && <option value="Errands">Errands</option>}
                                </select>
                            </div>}
                        {page === "Zermatt Shuttle" &&
                            <div>
                                <label htmlFor="visitors" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Name of the Passenger</label>
                                <input value={formdata.name_passenger} onChange={(e) => {
                                    const { value } = e.target;
                                    setFormdata({ ...formdata, name_passenger: value }); // If value is undefined, set it to an empty string
                                }} type="text" id="visitors" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="passenger" />
                            </div>}
                        {page === "Free Shuttle" &&
                            <div>
                                <label htmlFor="company" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Type of Passenger</label>
                                <select onChange={(e) => {
                                    const { value } = e.target;
                                    setFormdata({ ...formdata, type_passenger: value }); // If value is undefined, set it to an empty string
                                }} id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option selected>{formdata.type_passenger ? formdata.type_passenger :'Choose a passenger'}</option>
                                    <option value="LGR Employee">LGR Employee</option>
                                    <option value="LGR Guest">LGR Guest</option>
                                </select>
                            </div>}
                        {page !== "Expense Tracker" &&
                            <div>
                                <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Departure Time</label>
                                <input value={formdata.departure} onChange={(e) => {
                                    const { value } = e.target;
                                    setFormdata({ ...formdata, departure: value }); // If value is undefined, set it to an empty string
                                }} type="time" id="phone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
                            </div>}
                        <div>
                            <label htmlFor="website" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Starting Millage</label>
                            <input value={formdata.start_millage} onChange={(e) => {
                                const { value } = e.target;
                                setFormdata({ ...formdata, start_millage: value }); // If value is undefined, set it to an empty string
                            }} type="text" id="website" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="millage" />
                        </div>
                        {page !== "Expense Tracker" && <>
                            <div>
                                <label htmlFor="visitors" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">No. of Pax</label>
                                <input value={formdata.pax} onChange={(e) => {
                                    const { value } = e.target;
                                    setFormdata({ ...formdata, pax: value }); // If value is undefined, set it to an empty string
                                }} type="number" id="visitors" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="0" />
                            </div>
                            <div>
                                <label htmlFor="visitors" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Destination</label>
                                <input value={formdata.destination} onChange={(e) => {
                                    const { value } = e.target;
                                    setFormdata({ ...formdata, destination: value }); // If value is undefined, set it to an empty string
                                }} type="text" id="visitors" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="destination" />
                            </div>
                            <div>
                                <label htmlFor="visitors" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Arrival Time</label>
                                <input value={formdata.arrival} onChange={(e) => {
                                    const { value } = e.target;
                                    setFormdata({ ...formdata, arrival: value }); // If value is undefined, set it to an empty string
                                }} type="time" id="visitors" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
                            </div> </>}
                        <div>
                            <label htmlFor="visitors" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Ending Mileage</label>
                            <input value={formdata.end_millage} onChange={(e) => {
                                const { value } = e.target;
                                setFormdata({ ...formdata, end_millage: value }); // If value is undefined, set it to an empty string
                            }} type="text" id="visitors" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="millage" />
                        </div>
                        {page !== "Free Shuttle" &&
                            <div>
                                <label htmlFor="visitors" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Price</label>
                                <input value={formdata.price} onChange={(e) => {
                                    const { value } = e.target;
                                    setFormdata({ ...formdata, price: value }); // If value is undefined, set it to an empty string
                                }} type="text" id="visitors" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="price" />
                            </div>}
                        <div>
                            <label htmlFor="visitors" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Remarks</label>
                            <input value={formdata.remarks} onChange={(e) => {
                                const { value } = e.target;
                                setFormdata({ ...formdata, remarks: value }); // If value is undefined, set it to an empty string
                            }} type="text" id="visitors" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="remarks..." />
                        </div>
                    </div>


                    <button type="submit" className="text-lagrand-white bg-lagrand-green hover:bg-[#2c4824] mb-10 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
                    <button type="button" onClick={()=>{
                        sessionStorage.removeItem("driverform")
                        setFormdata({})
                        window.location.reload()
                        }} className="text-lagrand-white ml-2 bg-red-600 hover:bg-red-700 mb-10 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Clear</button>
                </form>
            </div>}
        </section>
    )
}