import { useEffect, useState } from "react";
import fam from "../../../assets/FamilyGreen.png"
import logo from "../../../assets/logo.png"
import { Spinner } from "flowbite-react";
import Api from "../../../Api/api";
import time_convert from "../../../Components/Functions/time_convert";
import { useGetUser } from "../../../Hooks/useGetUser";
export default function RedeemReferral(props: any) {
    const { code } = props
    const { user } = useGetUser()
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [authorize, setAuthorize] = useState(false)
    const [error, setError] = useState()
    const [load,setLoad] = useState(false)
    const handleSignIn = async () => {
        await Api.login("login", { username: username, password: password }).then((data: any) => {
            setError(data)
            if (data === "Success") {
                window.location.reload()
            }
        })
    }
    const confirm = async () => {
        setLoad(true)
        const currentDate = new Date();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
        const day = currentDate.getDate().toString().padStart(2, '0');
        const year = currentDate.getFullYear();
        const time = currentDate.getHours() + ':' + currentDate.getMinutes()
        const formattedDateTime = `${month}/${day}/${year} ${time_convert.time(time)}`;
        let value: any[] = []
        let range = "Redeem Codes"

        value = [code.name.toUpperCase(), code.code, parseInt(code.amount), code.details, formattedDateTime]
        await Api.getbyid("spreadsheet",code.code).then((response:any)=>{
            if(response){
            }
            else{
               // alert("Redeem Code not Found/Expired")
                window.location.replace("/referral")
            }
        })
    
        await Api.post("spreadsheet", {
            "id": "1wtuZ21k16fWZOSjqy8iQf-UnSfggHpvUW8BA0BbHUi8",
            "range": `${range}!A:A`,
            "values": [
                value
            ]
        }).then(async (data: any) => {

            if (data.message === "Data added successfully.") {

                await Api.delete("spreadsheet", code.id)
                setAuthorize(true)
            }
            console.log(data)
            setLoad(false)
        })
    }
    if (!user) {
        return (

            <section className="mx-auto max-w-xl h-screen lg:flex place-items-center ">
                <div className="lg:p-20  pt-20 lg:bg-lagrand-white lg:rounded-xl lg:shadow-xl  ">
                    <form className="max-w-sm mx-10 lg:mx-auto" onSubmit={(e) => {
                        e.preventDefault();
                        handleSignIn();
                    }}>
                        <div className="mb-10">
                            <img src={logo} className="h-10 mx-auto" alt="la grande residence"></img>
                        </div>
                        <div className="mb-5 lg:w-80">
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Your username</label>
                            <input
                                onChange={(e) => setUsername(e.target.value)}
                                type="text" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5  " placeholder="username" required />
                        </div>
                        <div className="mb-5">
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">Your password</label>
                            <input
                                onChange={(e) => setPassword(e.target.value)}
                                type="password" id="password" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" required placeholder="*****" />
                        </div>
                        <div className="flex items-start mb-5">
                            {/** <label htmlFor="terms" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"> <a href="#" className="text-[#588a3c]  hover:underline hover:text-lagrand-green">Forgot Password</a></label> */}
                        </div>
                        <button type="submit" className="text-lagrand-white bg-[#588a3c] hover:bg-lagrand-green focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full py-2.5 text-center">Login</button>
                        {error && <h1 className={`text-sm  text-center mt-5 underline ${error == "Success" ? " text-lagrand-greenlight" : " text-red-700"}`}>{error}</h1>}
                    </form>


                </div>
            </section>
        )

    }
    return (
        <section className={`bg-center bg-fixed  text-lagrand-white h-screen md:bg-cover 0 bg-blend-multiply`}>

            <div className="lg:px-[200px]  pt-5 md:px-[100px] w-full xl:px-[500px]  px-5">
                <div className="max-w-xl mx-auto p-6 bg-white  rounded-lg  ">
                    <div className="flex-shrink-0 mb-10">
                        <img className="h-10 mx-auto" src={logo} alt="La grande Family" />
                    </div>

                    <h5 className="text-xl mb-5 font-bold leading-none text-gray-900 ">Details</h5>
                    <ul role="list" className="divide-y divide-gray-200 ">
                        <li className="pb-3">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <img className=" w-12 h-7 rounded-full" src={fam} alt="La grande Family" />
                                </div>
                                <div className="flex-1 min-w-0">
                                    <p className="text-sm font-medium text-gray-900 truncate ">
                                        {code?.name}
                                    </p>

                                </div>
                    
                            </div>
                        </li>
                        <hr></hr>
                        <li className="pb-3">
                            <div className="flex items-center">

                                <div className="flex-1 min-w-0">
                                    <p className="text-sm font-medium text-gray-900 truncate ">
                                        Code
                                    </p>

                                </div>
                                <div className=" items-center text-base font-semibold text-gray-900 ">

                                {code.code}
                                </div>
                            </div>
                        </li>
                        <li className="pb-3">
                            <div className="flex items-center">

                                <div className="flex-1 min-w-0">
                                    <p className="text-sm font-medium text-gray-900 truncate ">
                                        Details
                                    </p>

                                </div>
                                <div className=" items-center text-base font-semibold text-gray-900 ">

                                    {code.details}

                                </div>
                            </div>
                        </li>
                        <li className="pb-3">
                            <div className="flex items-center">

                                <div className="flex-1 min-w-0">
                                    <p className="text-sm font-medium text-gray-900 truncate ">
                                        Amount
                                    </p>

                                </div>
                                <div className=" items-center text-base font-semibold text-gray-900 ">

                                    {code.amount}

                                </div>
                            </div>
                        </li>

                    </ul>


                    {authorize ? <h5 className="text-xl mb-5 font-bold text-center leading-none text-lagrand-greenlight ">Redeem Successful</h5> :
                        <div className="text-center"><h5 className="text-sm mb-5 mt-5 font-bold text-center leading-none text-red-600 ">Only the staff member whose name is listed can redeem this code!</h5>
                            {load? <Spinner className="mb-5 " color="success" aria-label="Success spinner example" />:
                            <button type="submit" onClick={() => { confirm() }} className="text-lagrand-white  bg-[#588a3c] hover:bg-lagrand-green focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full py-2.5 text-center">Redeem</button>
                        }
                        </div>}


                </div>
            </div>
        </section>
    )
}