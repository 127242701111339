import { useEffect, useState } from "react";

import NotFound from "../../../Components/notFound";
import Sidebar from "../Sidebar";
import Api from "../../../Api/api";
import { useGetUser } from "../../../Hooks/useGetUser";
import time_convert from "../../../Components/Functions/time_convert";
import PageBanner from "../../../Components/PageBanner";
export default function ReferalPortal() {
    const { user } = useGetUser()
    const [formdata, setFormdata] = useState<any>({})
    const [other, setOther] = useState(false) // Or specify the type of your referral object
    const [roomType, setRoomType] = useState<any>({})
    const [affiliate, setAffliate] = useState<boolean>(false)
    useEffect(() => {
        const session = sessionStorage.getItem("referralform")
        if (session) {
            setFormdata(JSON.parse(session))
        }
        const range = "Referral System"
        Api.post("spreadsheet/all", {
            "id": "1wtuZ21k16fWZOSjqy8iQf-UnSfggHpvUW8BA0BbHUi8",
            "range": `${range}!A:B`,

        }).then((response) => {
            const { data } = response
            setRoomType(data)
        })
    }, [])

    useEffect(() => {
        sessionStorage.setItem("referralform", JSON.stringify(formdata))
    }, [formdata])

    const onsave = () => {
        const currentDate = new Date();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
        const day = currentDate.getDate().toString().padStart(2, '0');
        const year = currentDate.getFullYear();
        const time = currentDate.getHours() + ':' + currentDate.getMinutes()
        const formattedDateTime = `${month}/${day}/${year} ${time_convert.time(time)}`;
        let value: any[] = []
        let range = "Referral"

        value = [formdata.EmployeeName.toUpperCase(), formdata.GuestName, formdata.Services, parseInt(formdata.points), formattedDateTime]
        Api.post("spreadsheet", {
            "id": "1wtuZ21k16fWZOSjqy8iQf-UnSfggHpvUW8BA0BbHUi8",
            "range": `${range}!A:A`,
            "values": [
                value
            ]
        }).then((data: any) => {

            if (data.message === "Data added successfully.") {

                sessionStorage.clear()
            }
            console.log(data)
            alert(data.message)
            window.location.reload()
        })


    };
    if (user?.role !== "Superadmin" && user?.role !== "Marketing") {
        return (<div className="">
            <Sidebar></Sidebar>
            <div className="lg:ml-72 ml-40">
                <NotFound />
            </div>
        </div>)

    }
    return (
        <section>
            <div className="">
                <Sidebar></Sidebar>
            </div>
            <div className="lg:ml-72 ml-40">
                <section className={`bg-center bg-fixed text-lagrand-white  md:bg-cover landing bg-gray-400 bg-blend-multiply`}>
                    <PageBanner Heading="Referral Portal" SubHeading="" />

                    <div className="lg:px-[200px] bg-lagrand-white h-screen pt-5 md:px-[100px] xl:px-[500px] px-5">


                        <form onSubmit={(e) => {
                            e.preventDefault();
                            onsave();
                        }} >
                            <h1 className="text-3xl font-thin mb-5 text-black text-center">Referral Portal</h1>
                            <div className="grid gap-6 mb-6 md:grid-cols-2">
                                {/* <div className="col-span-2">
                            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Name : {referral.name} <p onClick={
                                () => {
                                    localStorage.clear()
                                    window.location.reload()
                                }
                            } className="text-red-600 underline">Logout</p></label>
                        </div> */}
                                <div>
                                    <label htmlFor="visitors" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Employee Name</label>
                                    <input value={formdata.EmployeeName} onChange={(e) => {
                                        const { value } = e.target;
                                        setFormdata({ ...formdata, EmployeeName: value }); // If value is undefined, set it to an empty string
                                    }} type="text" id="visitors" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Employee Name" />
                                </div>
                                <div>
                                    <label htmlFor="visitors" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Guest Name</label>
                                    <input value={formdata.GuestName} onChange={(e) => {
                                        const { value } = e.target;
                                        setFormdata({ ...formdata, GuestName: value }); // If value is undefined, set it to an empty string
                                    }} type="text" id="visitors" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Guest name" />
                                </div>

                                <div>
                                    <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Services</label>
                                    <select id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        onChange={(e) => {

                                            const { value } = e.target;
                                            setFormdata({ ...formdata, Services: value });
                                            // If value is undefined, set it to an empty string
                                        }}
                                        required>
                                        <option selected>{formdata.Services ? formdata.Services : 'Choose a type of Service'}</option>
                                        <option value="Room Accomodation">Room Accomodation</option>
                                        <option value="Spa">Spa</option>
                                        <option value="Emergency">Laundry</option>
                                        <option value="Emergency">Shuttle</option>
                                    </select>
                                </div>
                                {formdata.Services !== "Room Accomodation" &&
                                    <div>
                                        <label htmlFor="visitors" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Points Reward</label>
                                        <input value={formdata.points} onChange={(e) => {
                                            const { value } = e.target;
                                            setFormdata({ ...formdata, points: value }); // If value is undefined, set it to an empty string
                                        }} type="text" id="visitors" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="points" />
                                    </div>}
                                {formdata.Services === "Room Accomodation" &&
                                    <div>
                                        <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Room Types</label>
                                        <select id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            onChange={(e) => {

                                                const { value } = e.target;

                                                setOther(false)
                                                setFormdata({ ...formdata, points: value });


                                                // If value is undefined, set it to an empty string
                                            }}
                                            required >
                                            <option selected>Choose a type of Room</option>

                                            {roomType.map((item: any) => (
                                                <option value={item.PointsRewards}>{item.RoomTypes}</option>
                                            ))}
                                        </select>
                                    </div>}
                                {/* <div className="flex items-center">
                                    <input id="link-checkbox" onChange={()=>{setAffliate(!affiliate)}} type="checkbox" value="" checked={affiliate} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="link-checkbox" className="ms-2 text-sm font-medium flex  text-gray-900 dark:text-gray-300">Check if this form is for<p className=" ms-1 text-lagrand-greenlight underline">Affiliate</p>.</label>
                                </div> */}
                            </div>


                            <button type="submit" className="text-lagrand-white bg-lagrand-green hover:bg-[#2c4824] mb-10 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
                            <button type="button" onClick={() => {
                                sessionStorage.removeItem("referralform")
                                setFormdata({})
                                window.location.reload()
                            }} className="text-lagrand-white ml-2 bg-red-600 hover:bg-red-700 mb-10 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Clear</button>
                        </form>
                    </div>
                </section>
            </div>
        </section>
    )
}